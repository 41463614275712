import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Poem from "../../../../../components/poem"
import PaperStack from "../../../../../components/paper-stack"
import VideoPlayer from "../../../../../components/video-player"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  const data = useStaticQuery(graphql`
    query NachtgesaengeBrotQuery {
      poster: file(relativePath: { eq: "kurse/poster-lierau-epoche.png" }) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/zahlenspiele" />
      }
    >
      <Seo title="Nachtgesänge" />
      <Box>
        <Stack>
          <Heading as="h1" level={2}>
            Nachtgesänge
          </Heading>
          <Paragraph>
            Die Nacht könnte bei Hölderlin aber auch übertragen für eine Zeit
            des Übergangs stehen: Das goldene Zeitalter der Antike ist längst
            vergangen, die Götter sind fern, doch eine mündige, derart
            hochentwickelte moderne Kultur ist noch nicht angebrochen.
          </Paragraph>

          <Paragraph>
            Diese Bedeutung erhält die Nacht auch in Hölderlins Elegie ›Brod und
            Wein‹, einem weiteren Meisterwerk, das aus der Überarbeitung eines
            früheren Gedichts mit dem Titel ›Die Nacht‹ hervorgegangen ist.
            ›Brod und Wein‹ beginnt mit einer abendlichen Szene, über die nach
            und nach die Nacht hereinbricht.
          </Paragraph>

          <PaperStack>
            <Poem>
              <p>Rings um ruhet die Stadt; still wird die erleuchtete Gasse,</p>
              <p>Und, mit Fackeln geschmückt, rauschen die Wagen hinweg.</p>
              <p>[...]</p>
              <p>Still in dämmriger Luft ertönen geläutete Glocken,</p>
              <p>Und der Stunden gedenk rufet ein Wächter die Zahl.</p>
              <p>
                Jetzt auch kommet ein Wehn und regt die Gipfel des Hains auf,
              </p>
              <p>Sieh! und das Schattenbild unserer Erde, der Mond,</p>
              <p>
                Kommet geheim nun auch; die Schwärmerische, die Nacht kommt,
              </p>
              <p>Voll mit Sternen und wohl wenig bekümmert um uns,</p>
              <p>
                Glänzt die Erstaunende dort, die Fremdlingin unter den Menschen,
              </p>
              <p>Über Gebirgeshöhn traurig und prächtig herauf.</p>
              <br />
              <p>Wunderbar ist die Gunst der Hocherhabnen und niemand</p>
              <p>Weiß, von wannen und was einem geschiehet von ihr.</p>
              <p>[...]</p>
              <p>Aber zuweilen liebt auch klares Auge den Schatten</p>
              <p>Und versuchet zu Lust, eh es die Not ist, den Schlaf,</p>
              <p>Oder es blickt auch gern ein treuer Mann in die Nacht hin,</p>
              <p>Ja, es ziemet sich, ihr Kränze zu weihn und Gesang,</p>
              <p>Weil den Irrenden sie geheiliget ist und den Toten,</p>
              <p>Selber aber besteht, ewig, in freiestem Geist.</p>
              <p>Aber sie muß uns auch, daß in der zaudernden Weile,</p>
              <p>Daß im Finstern für uns einiges Haltbare sei,</p>
              <p>Uns die Vergessenheit und das Heiligtrunkene gönnen,</p>
              <p>Gönnen das strömende Wort, das, wie die Liebenden, sei,</p>
              <p>Schlummerlos, und vollern Pokal und kühneres Leben,</p>
              <p>Heilig Gedächtnis auch, wachend zu bleiben bei Nacht</p>
              <p>[...]</p>
            </Poem>
          </PaperStack>
          <Paragraph>
            Die Beschäftigung mit dem Thema der Nacht verbindet Hölderlin mit
            den Dichtern der Romantik. Doch ganz so eindeutig lässt er sich
            dieser Epoche nicht zuordnen. Warum erklärt unsere
            Ausstellungsvermittlerin Sylvia Lierau in diesem Video…
          </Paragraph>
          <VideoPlayer src="kurse/lierau-epoche" poster={data.poster} />
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
